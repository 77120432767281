exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-concept-index-jsx": () => import("./../../../src/pages/concept/index.jsx" /* webpackChunkName: "component---src-pages-concept-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

